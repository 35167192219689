<template>
  <div id="notification">
    <transition name="fade" appear>
      <div class="modal-overlay"></div>
    </transition>
    <transition name="slide-fade" appear>
      <div class="modal">
        <h1>Alerte</h1>
        <p>Votre paiement n'a pas abouti.</p>
        <button class="button cancel" @click="close">Fermer</button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Cancel',
  methods: {
    async close() {
      this.$emit('close');
    },
  },
};
</script>
