<template>
  <div id="notification">
    <transition name="fade" appear>
      <div class="modal-overlay"></div>
    </transition>
    <transition name="slide-fade" appear>
      <div class="modal">
        <h1>Alerte</h1>
        <p>Une fois les informations validées, vous ne pourrez plus les modifier.<br /><br />Êtes-vous sûr.e de ces informations ?</p>
        <button class="button validate" @click="validate">Valider</button>
        <button class="button cancel" @click="cancel">Annuler</button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Deconnexion',
  methods: {
    async validate() {
      this.$emit('validate');
      this.$emit('cancel');
    },
    async cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
