<template>
  <div id="notification">
    <transition name="fade" appear>
      <div class="modal-overlay"></div>
    </transition>
    <transition name="slide-fade" appear>
      <div class="modal">
        <h1>Notification</h1>
        <p>Le paiement a bien été effectué.</p>
        <button class="button validate" @click="validate">Valider</button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Success',
  methods: {
    async validate() {
      this.$emit('validate');
    },
  },
};
</script>
