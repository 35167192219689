<template>
  <!-- Boutons de refus -->
  <div v-if="status >= 100 && status <= 299" id="statut">
    <!-- Bouton de refus pour la partie A -->
    <div class="failure waiting" id="refus"  v-if="whichPart == 'A'">
      <p class="info mt-0">Vous ne souhaitez plus entrer <br> en médiation ?</p>
      <button type="button" @click="this.$emit('click', 10)">
        Cliquez ici <i class="icon-arrow-right"></i>
      </button>
      
    </div>

    <!-- Bouton de refus pour la partie B -->
    <div class="failure waiting" id="refus" v-if="whichPart == 'B'">
      <p class="info mt-0">Vous ne souhaitez pas entrer <br> en médiation ?</p>
      <button type="button"  @click="this.$emit('click', 20)"
        >Cliquez ici <i class="icon-arrow-right"></i>
      </button>
    </div>
  </div>

  <!-- Le bouton est transformé en champ d'information en cas de succès -->
  <div v-if="status == 1" id="statut">
    <div class="success accepte" type="button" id="refus" style="cursor: default">
      <span>
        La médiation<br />
        est un succès<br />
      </span>
    </div>
  </div>

  <!-- Le bouton est transformé en champ d'information en cas d'échec -->
  <div v-if="status >= 10 && status <= 99" id="statut">
    <div class="failure refuse" type="button" id="refus" style="cursor: default">
      <span>
        La médiation<br />
        est un échec<br /><br />
        Vous trouverez le compte rendu de la médiation dans vos mails
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediationButton',
  props: {
    status: { type: Number },
    whichPart: { type: String },
  },
};
</script>
