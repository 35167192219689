<template>
  <header>
    <div class="container">
      <div class="row">
        <div class="left col-12 col-md-4">
          <img src="../assets/img/allianz_pj_white.svg" alt="logo_allianz_pj" />
          <img src="../assets/img/logo_avoloi-horizontal_white.svg" alt="" />
        </div>
        <div class="center col-12 col-md-6">
          <div>
            <span class="text-uppercase">Mon espace</span>
            <img src="../assets/img/mediation-seul-white.svg" alt="" />
          </div>
        </div>
        <div class="right col-12 col-md-2">
          <i class="icon-quit" @click="logout"></i>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    logout() {
      localStorage.removeItem('AVOLOI_MEDIATION_AUTH_TOKEN');
      // this.$router.push('/')
      window.location = window.location.origin;
    },
  },
};
</script>
