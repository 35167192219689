<template>
  <div id="notification">
    <transition name="fade" appear>
      <div class="modal-overlay"></div>
    </transition>
    <transition name="slide-fade" appear>
      <div class="modal">
        <h1>Alerte</h1>
        <p>Votre session a expiré. Merci de vous reconnecter.</p>
        <button class="button validate" @click="close">Se reconnecter</button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Deconnexion',
  methods: {
    async close() {
      await localStorage.removeItem('AVOLOI_MEDIATION_AUTH_TOKEN');
      window.location = window.location.origin;
    },
  },
};
</script>
