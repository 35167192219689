<template>

  <!-- Google Calendar Appointment Scheduling begin -->
  <iframe v-if="displayCalendar" 
    :src="srcCalendar" 
    style="border: 0" width="100%" height="600" frameborder="0"
  ></iframe>
  <!-- end Google Calendar Appointment Scheduling -->

  <div v-if="displayCalendar">
    Le médiateur se rapprochera de vous pour fixer un rendez-vous
  </div>

  <!-- Bloc rendez-vous -->
  <div id="date" class="col-lg-7" v-if="loaded">
    <!-- Prendre rendez-vous d'exploration (AB), indiviuel (AB) ou choisir les créneaux du rendez-vous collectif (Uniquement partie A) -->
    <!--
    <form class="choose-date" id="dateForm" v-if="checkStatusRdv(status) && availabilities">
      <Swiper
        :slides-per-view="2"
        :navigation="{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }"
        :breakpoints="{
          '320': {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          '576': {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          '768': {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          '992': {
            slidesPerView: 3,
            spaceBetween: 0,
          },
        }"
      >
        <div class="swiper-button-prev"></div>
        <SwiperSlide v-for="day of availabilities" :key="day.id">
          <div class="day-header">
            <span style="text-transform: capitalize">{{ new Date(day.day).toLocaleDateString('fr-FR', this.dayOption) }}</span>
            <span style="text-transform: capitalize">{{ new Date(day.day).toLocaleDateString('fr-FR', this.monthOptions) }}</span>
          </div>
          <div class="wrapper-slots">
            <div class="slot" v-for="slot in day.slots" :key="slot">
              <input v-if="singleSlot" type="radio" :id="`availability-${slot.start}`" name="availability" :value="slot" v-model="selectedSlot" />
              <input v-if="!singleSlot" type="checkbox" :id="`availability-${slot.start}`" name="availability" :value="slot" v-model="selectedSlot" />
              <label class="btn btn-outline-primary" :for="`availability-${slot.start}`">{{ `${String(new Date(slot.start).getHours()).padStart(2, '0')}h${String(new Date(slot.start).getMinutes()).padStart(2, '0')}` }}</label>
            </div>
          </div>
        </SwiperSlide>
        <div class="swiper-button-next"></div>
      </Swiper>
    </form>
    -->
    <!-- Voir le rendez-vous ou les créneaux choisis -->
    <div v-if="checkStatusTakenRdv(status) && dateRdv" class="date-rdv">
      <div v-if="status < 270 || status == 279">
        <p>Votre rendez-vous a lieu le :</p>
        <span style="text-transform: capitalize">{{ dateRdv }}</span>
      </div>
      <div v-else>
        <p>Les créneaux choisis sont :</p>
        <p style="text-transform: capitalize" v-for="date in dateRdv" :key="date">{{ date }}</p>
      </div>
    </div>

    <!-- Choisir un créneau parmi les 5 proposés (Uniquement partie B) -->
    <div class="choose-slot" v-if="checkStatusRdv(status) && availableSlots">
      <div v-for="slot of availableSlots" :key="slot.id">
        <div class="slot">
          <input v-if="singleSlot" type="radio" :id="`availability-${slot.start}`" name="availability" :value="slot" v-model="selectedSlot" />
          <label class="btn btn-outline-primary" :for="`availability-${slot.start}`" style="text-transform: capitalize">{{ new Date(slot.start).toLocaleDateString('fr-FR', this.dayOption) + ' ' + new Date(slot.start).toLocaleDateString('fr-FR', this.monthOptions) }} {{ `${String(new Date(slot.start).getHours()).padStart(2, '0')}h${String(new Date(slot.start).getMinutes()).padStart(2, '0')}` }}</label>
        </div>
      </div>
    </div>
  </div>

  <div id="date" class="col-lg-7" v-else>
    <clip-loader style="padding: 70px 0" :color="color" :size="size"></clip-loader>
  </div>

  <!-- Confirmation du rendez-vous, du ou des créneaux sélectionnés -->
  <div v-if="selectedSlot" class="selected-slot col-lg-6">
    <div v-if="singleSlot && selectedSlotDate.length != 0">
      <span style="font-weight: bold">Confirmez-vous le rendez-vous le {{ selectedSlotDate }} ?</span>
    </div>
    <div v-if="!singleSlot && selectedSlotDate.length != 0">
      <span style="font-weight: bold">Confirmez-vous les créneaux suivants ?</span>
      <div v-for="slot in selectedSlotDate" :key="slot">{{ slot }} <br /></div>
    </div>
    <div v-if="selectedSlotDate.length != 0">
      <button
        @click="
          selectedSlot = [];
          selectedSlotDate = [];
        "
      >
        NON
      </button>
      <button class="validate" v-if="singleSlot" @click="saveAppointmentOrSlot">OUI</button>
      <button class="validate" v-if="!singleSlot && selectedSlot.length == 5" @click="saveAppointmentOrSlot">OUI</button>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
SwiperCore.use([Navigation]);

export default {
  name: 'MediationButton',
  srcCalendar: null,
  displayCalendar: false,
  props: {
    status: { type: String },
    whichPart: { type: String },
    idCase: { type: String },
  },
  components: {
    Swiper,
    SwiperSlide,
    ClipLoader,
  },
  data() {
    return {
      // Chargement
      size: '50px',
      color: '#55183d',
      loaded: false,

      // Rendez-vous
      availabilities: null,
      availableSlots: null,
      singleSlot: true,
      selectedSlot: [],
      selectedSlotDate: [],
      monthOptions: {
        month: 'long',
        day: 'numeric',
      },
      dayOption: {
        weekday: 'long',
      },
      options: {
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      },
      rdvA: [130, 131, 132, 150, 151, 152, 170, 171, 172],
      rdvB: [240, 241, 242, 260, 261, 262, 270, 271, 272],
      rdvTakenA: [139, 159, 270, 271, 272, 279],
      rdvTakenB: [249, 269, 279],
      dateRdv: null,
    };
  },
  watch: {
    status: async function () {
      this.check();
    },

    availabilities: async function () {
      if ([130, 131, 132, 150, 151, 152, 240, 241, 242, 260, 261, 262].includes(this.status)) this.singleSlot = true;
      else this.singleSlot = false;
    },

    // Faire une belle date pour l'utilsateur
    selectedSlot: async function () {
      if (this.selectedSlot.length == 0) return;
      if (this.singleSlot) {
        var date = new Date(this.selectedSlot.start).toLocaleDateString('fr-FR', this.monthOptions);
        this.selectedSlotDate = `${date} à ${String(new Date(this.selectedSlot.start).getHours()).padStart(2, '0')}h${String(new Date(this.selectedSlot.start).getMinutes()).padStart(2, '0')}`;
      } else if (!this.singleSlot) {
        if (this.selectedSlot.length > 5) {
          this.selectedSlot.pop();
          return;
        }
        this.selectedSlotDate = [];
        for (let index = 0; index < this.selectedSlot.length; index++) {
          var date = new Date(this.selectedSlot[index].start).toLocaleDateString('fr-FR', this.monthOptions);
          this.selectedSlotDate[index] = ` ${date} à ${String(new Date(this.selectedSlot[index].start).getHours()).padStart(2, '0')}h${String(new Date(this.selectedSlot[index].start).getMinutes()).padStart(2, '0')} `;
        }
      }
    },
  },
  async mounted() {
    await this.check();
    this.loaded = true;
  },
  methods: {
    // Savoir quoi faire
    async check() {
      this.displayCalendar = false
      // if ([130, 131, 132, 240, 241, 242].includes(this.status)) {
      //   this.srcCalendar = 'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0Cil1Mkcf6YzpBuKdRh5XmjlRsgD1XwXMyJ8i4PEVZOM56mqjjN-1qhpO1G4jewK1gCp9sIvfr'
      //   this.displayCalendar = true
      // } else if ([150, 151, 152, 260, 261, 262].includes(this.status)) {
      //   this.srcCalendar = 'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3PUabkS3tmJ_o8TJUKPUK_UbBu0VToyeEsueF26G5iZbJ5dvQqHjseHD4QFRMSPGVwkp6Jnu5o'
      //   this.displayCalendar = true
      // }

      // if (this.checkStatusRdv(this.status)) {

      //   // Si à partir de 270 => "le mediateur va vous contatcter"
      //   if (this.status >= 270) {
      //     await this.getAvailabilities(type);
      //     this.availableSlots = await this.getSlots();
      //   } else {
      //     this.availabilities = await this.getAvailabilities(type);
      //   }
      // } else if (this.checkStatusTakenRdv(this.status)) {
      //   this.dateRdv = await this.getAppointments();
      // }
    },

    async chooseApp() {
      let newStatus = this.status;
      if ([130, 131, 132].includes(this.status)) {
        newStatus = 139
      } else if ([240, 241, 242].includes(this.status)) {
        newStatus = 249
      } else if ([150, 151, 152].includes(this.status)) {
        newStatus = 159
      } else if ([260, 261, 262].includes(this.status)) {
        newStatus = 269
      }
      await this.$emit('update', newStatus);
      this.selectedSlot = [];
      this.selectedSlotDate = [];
    },

    // Récupérer les disponibilités
    getAvailabilities(type) {
      return this.$axiosConfigInstance
        .get('/availabilities', {
          params: {
            type: type,
            numberOfDays: 5,
            dateStart: new Date().toISOString(),
          },
        })
        .then((response) => {
          return response.data.availabilities;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Savoir quoi sauvegarder
    saveAppointmentOrSlot() {
      if (this.status < 270) {
        this.saveAppointment();
      } else {
        this.saveSlot();
      }
    },

    // Sauvegarder le ou les créneaux de la personne
    saveAppointment() {
      var customer = this.whichPart;
      if ([130, 131, 132, 240, 241, 242].includes(this.status)) var type = 'exploration';
      if ([150, 151, 152, 260, 261, 262].includes(this.status)) var type = 'individual';
      if ([170, 171, 172, 270, 271, 272].includes(this.status)) {
        var type = 'group';
        customer = 'AB';
      }
      var tab = [];
      if (this.singleSlot) tab.push(this.selectedSlot.start);
      else {
        this.selectedSlot.forEach((element) => {
          tab.push(element.start);
        });
        customer = 'AB';
      }
      this.$axiosConfigInstance
        .post(`/appointments`, {
          dateAppointment: tab,
          type: type,
          idCase: this.idCase,
          customer: customer,
        })
        .then(() => {
          if (this.whichPart == 'A') {
            switch (this.status) {
              case 130:
              case 131:
              case 132:
                this.updateStatus(139);
                break;
              case 150:
              case 151:
              case 152:
                this.updateStatus(159);
                break;
              case 170:
              case 171:
              case 172:
                this.updateStatus(270);
                break;
            }
          } else if (this.whichPart == 'B') {
            switch (this.status) {
              case 240:
              case 241:
              case 242:
                this.updateStatus(249);
                break;
              case 260:
              case 261:
              case 262:
                this.updateStatus(269);
                break;
              case 270:
              case 271:
              case 272:
                this.updateStatus(279);
                break;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Sauvegarder le rendez-vous choisi par la partie B lors de l'entretien collectif
    saveSlot() {
      var filteredSlots = this.availableSlots.filter((slot) => slot.idEvent != this.selectedSlot.idEvent);
      var slotsToDelete = [];
      filteredSlots.forEach((slot) => {
        slotsToDelete.push(slot.idEvent);
      });
      this.$axiosConfigInstance
        .put('/appointments', {
          updateEvent: this.selectedSlot.idEvent,
          deleteEvents: slotsToDelete,
        })
        .then(() => {
          this.updateStatus(279);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Récupérer le ou les créneaux de la personne
    getAppointments() {
      var customer = this.whichPart;
      if ([130, 131, 132, 240, 241, 242, 139, 249].includes(this.status)) var type = 'exploration';
      if ([150, 151, 152, 260, 261, 262, 159, 269].includes(this.status)) var type = 'individual';
      if ([170, 171, 172, 270, 271, 272, 279].includes(this.status)) {
        var type = 'group';
        customer = 'AB';
      }
      return this.$axiosConfigInstance
        .get('/appointments', {
          params: {
            idCase: this.idCase,
            customer: customer,
            type: type,
            ...(this.status == 279 ? { isConfirmed: true } : {}),
          },
        })
        .then((response) => {
          if (this.status >= 270 && this.status != 279) {
            var tab = [];
            response.data.forEach((specificDate) => {
              var date = new Date(specificDate.start).toLocaleDateString('fr-FR', this.options);
              var time = String(new Date(specificDate.start).getHours()).padStart(2, '0') + 'h' + String(new Date(specificDate.start).getMinutes()).padStart(2, '0');
              tab.push(`${date} à ${time}`);
            });
            return tab;
          } else {
            var dates = [];
            response.data.forEach((element) => {
              dates.push(new Date(element.start));
            });
            var date = new Date(Math.max.apply(null, dates)).toLocaleDateString('fr-FR', this.options);
            var time = String(new Date(Math.max.apply(null, dates)).getHours()).padStart(2, '0') + 'h' + String(new Date(Math.max.apply(null, dates)).getMinutes()).padStart(2, '0');
            return `${date} à ${time}`;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Récupérer le ou les créneaux de la personne
    getSlots() {
      return this.$axiosConfigInstance
        .get('/appointments', {
          params: {
            idCase: this.idCase,
            customer: 'AB',
            type: 'group',
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Ressortir les rendez-vous de l'affaire
    checkStatusRdv(status) {
      if (this.whichPart == 'A') return this.rdvA.indexOf(status) > -1;
      if (this.whichPart == 'B') return this.rdvB.indexOf(status) > -1;
    },
    // Ressortir les rendez-vous de l'affaire
    checkStatusTakenRdv(status) {
      if (this.whichPart == 'A') return this.rdvTakenA.indexOf(status) > -1;
      if (this.whichPart == 'B') return this.rdvTakenB.indexOf(status) > -1;
    },

    async updateStatus(status) {
      await this.$emit('update', status);
      this.selectedSlot = [];
      this.selectedSlotDate = [];
    },
  },
};
</script>

<style scoped>
button {
  border: 0;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  background-color: #fff;
  font-family: 'Spartan', sans-serif;
  color: #fff;
  font-weight: bold;
  width: 70px;
  margin: 5px 5px;
}

button:first-child {
  background: #f34270;
}

button.validate {
  background: #2fe690;
}
</style>
